<template>
  <div>
    <Quizzes />
  </div>
</template>

<script>
import Quizzes from "../components/Quizzes/index.vue";
export default {
  components: {
    Quizzes,
  },
};
</script>

<style></style>
