<template>
  <div>
    <div>
      <h5 class="mb-4">
        {{ $t("quizzes") }}
        <span class="text-muted">{{ count }}</span>
      </h5>
      <p class="text-muted">{{ $t(`quizzesDescription`) }}</p>
    </div>
    <export-excell
      :checkedList="checkedList"
      :name="`${checkedList.length} ${$t('quizzes')}`"
      :selectedAllItemWithoutPage="selectedAllItemWithoutPage"
      :fields="fields"
      :count="count"
      @getAll="GET_ALL"
    ></export-excell>
    <Table
      checkable
      :check-value="checkedList"
      @change:selectAllWithoutPage="SET_SELECT_ALL_WITHOUT_PAGE"
      @change:checkedList="(e) => (checkedList = e)"
      :hideHeader="inside"
      :count="count"
      :headers="headers"
      :searchPlaceholder="$t('searchQuiz')"
      @change:search="SET_SEARCH"
      :loading="isLoading"
      :items="items"
      no-card
    >
      <template #name="{ item }">
        <router-link :to="`/quiz-statistics/quiz/${item.id}`">
          <div>{{ item.name }}</div>
        </router-link>
      </template>
      <template #createdAt="{ item }">
        <div>{{ formatter(item.createdAt) }}</div>
      </template>
      <template #status="{ item }">
        <router-link :to="`/quiz-statistics/quiz/${item.id}`">
          <button class="btn donate btn-xs btn-pill">
            {{ $t("showDetail") }}
          </button>
        </router-link>
      </template>
    </Table>
    <div v-if="!inside" class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
  </div>
</template>

<script>
import Table from "../Tables/index.vue";
import { formatter } from "gurhan/helpers/Date";
import { Currency } from "gurhan/helpers/Currency";
import BoostrapPagination from "gurhan/vue/BootstrapPagination.vue";
import ExportExcell from "../ReportCenter/ExportExcell.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    ExportExcell,
    BoostrapPagination,
    Table,
  },
  props: {
    inside: {
      default: false,
      type: Boolean,
    },
    limit: {
      default: 10,
    },
  },
  data() {
    return {
      checkedList: [],
      selectedAllItemWithoutPage: false,
      fields: {},
      search: "",
      items: [],
      isLoading: true,
      count: 0,
      page: 1,
      headers: [
        {
          title: this.$t("quizName"),
          value: "name",
          col: 12,
          md: 4,
          lg: 4,
          xl: 4,
        },
        {
          title: this.$t("createdAt"),
          value: "createdAt",
          col: 12,
          md: 4,
          lg: 4,
          xl: 4,
        },
        {
          title: this.$t("situation"),
          value: "status",
          col: 12,
          md: 4,
          lg: 4,
          xl: 4,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getExcelName"]),
    pages() {
      return Math.ceil(this.count / 10);
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  methods: {
    formatter,
    Currency,
    SET_SELECT_ALL_WITHOUT_PAGE(all) {
      this.selectedAllItemWithoutPage = all;
    },
    CREATE_EXCEL_FIELD() {
      this.fields = {
        [`${this.$t("quizName")}`]: {
          field: "name",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("description")}`]: {
          field: "description",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("duration")}`]: {
          field: "duration",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("createdAt")}`]: {
          field: "createdAt",
          callback: (value) => {
            return this.formatter(value);
          },
        },
      };
    },
    async GET_ALL(response, fromCache) {
      const responsed = await this.$api.get(
        `Quizzes?search=${this.search}&page=1&limit=${this.count}`
      );
      if (responsed.message === "OK") {
        this.checkedList = responsed.data.items;
        response(responsed.data.items, fromCache);
      }
    },
    toHHMMSS(secs) {
      var sec_num = parseInt(secs, 10);
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor(sec_num / 60) % 60;
      var seconds = sec_num % 60;

      return [hours, minutes, seconds]
        .map((v) => (v < 10 ? "0" + v : v))
        .filter((v, i) => v !== "00" || i > 0)
        .join(":");
    },
    courseRateClass(rate) {
      if (rate > 85) return "text-success";
      else if (rate > 70) return "text-info";
      else if (rate > 50) return "text-warning";
      else return "text-danger";
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.replace({ query: { page } });
      this.GET_ITEMS();
    },
    SET_SEARCH(search) {
      this.search = search;
      this.page = 1;
      this.$router.replace({ query: { search, page: 1 } });
      this.GET_ITEMS();
    },
    async GET_ITEMS() {
      this.isLoading = true;
      const response = await this.$api.get(
        `Quizzes?search=${this.search}&page=${this.page}&limit=${this.limit}`
      );
      if (response.message === "OK") {
        this.items = response.data.items;
        this.count = response.data.count;
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.CREATE_EXCEL_FIELD();
    const q = this.$route.query;
    if (!isNaN(Number(q.page))) this.page = Number(q.page);
    this.GET_ITEMS();
  },
};
</script>

<style></style>
